import { useEffect } from 'react';

const useShowConsole = () => {
  useEffect(() => {
    console.clear()
    console.log(`                                                                                                                                                                                      
                                                                                                                                                                                      
TTTTTTTTTTTTTTTTTTTTTTT                                            OOOOOOOOO                            TTTTTTTTTTTTTTTTTTTTTTT                                   hhhhhhh             
T:::::::::::::::::::::T                                          OO:::::::::OO                          T:::::::::::::::::::::T                                   h:::::h             
T:::::::::::::::::::::T                                        OO:::::::::::::OO                        T:::::::::::::::::::::T                                   h:::::h             
T:::::TT:::::::TT:::::T                                       O:::::::OOO:::::::O                       T:::::TT:::::::TT:::::T                                   h:::::h             
TTTTTT  T:::::T  TTTTTTooooooooooo   ppppp   ppppppppp        O::::::O   O::::::Onnnn  nnnnnnnn         TTTTTT  T:::::T  TTTTTTeeeeeeeeeeee        cccccccccccccccch::::h hhhhh       
        T:::::T      oo:::::::::::oo p::::ppp:::::::::p       O:::::O     O:::::On:::nn::::::::nn               T:::::T      ee::::::::::::ee    cc:::::::::::::::ch::::hh:::::hhh    
        T:::::T     o:::::::::::::::op:::::::::::::::::p      O:::::O     O:::::On::::::::::::::nn              T:::::T     e::::::eeeee:::::ee c:::::::::::::::::ch::::::::::::::hh  
        T:::::T     o:::::ooooo:::::opp::::::ppppp::::::p     O:::::O     O:::::Onn:::::::::::::::n             T:::::T    e::::::e     e:::::ec:::::::cccccc:::::ch:::::::hhh::::::h 
        T:::::T     o::::o     o::::o p:::::p     p:::::p     O:::::O     O:::::O  n:::::nnnn:::::n             T:::::T    e:::::::eeeee::::::ec::::::c     ccccccch::::::h   h::::::h
        T:::::T     o::::o     o::::o p:::::p     p:::::p     O:::::O     O:::::O  n::::n    n::::n             T:::::T    e:::::::::::::::::e c:::::c             h:::::h     h:::::h
        T:::::T     o::::o     o::::o p:::::p     p:::::p     O:::::O     O:::::O  n::::n    n::::n             T:::::T    e::::::eeeeeeeeeee  c:::::c             h:::::h     h:::::h
        T:::::T     o::::o     o::::o p:::::p    p::::::p     O::::::O   O::::::O  n::::n    n::::n             T:::::T    e:::::::e           c::::::c     ccccccch:::::h     h:::::h
      TT:::::::TT   o:::::ooooo:::::o p:::::ppppp:::::::p     O:::::::OOO:::::::O  n::::n    n::::n           TT:::::::TT  e::::::::e          c:::::::cccccc:::::ch:::::h     h:::::h
      T:::::::::T   o:::::::::::::::o p::::::::::::::::p       OO:::::::::::::OO   n::::n    n::::n           T:::::::::T   e::::::::eeeeeeee   c:::::::::::::::::ch:::::h     h:::::h
      T:::::::::T    oo:::::::::::oo  p::::::::::::::pp          OO:::::::::OO     n::::n    n::::n           T:::::::::T    ee:::::::::::::e    cc:::::::::::::::ch:::::h     h:::::h
      TTTTTTTTTTT      ooooooooooo    p::::::pppppppp              OOOOOOOOO       nnnnnn    nnnnnn           TTTTTTTTTTT      eeeeeeeeeeeeee      cccccccccccccccchhhhhhh     hhhhhhh
                                      p:::::p                                                                                                                                         
                                      p:::::p                                                                                                                                         
                                     p:::::::p                                                                                                                                        
                                     p:::::::p                                                                                                                                        
                                     p:::::::p                                                                                                                                        
                                     ppppppppp                                                                                                                                        
                                                                                                                                                                                      `)
  }, [])
}

export {
  useShowConsole
}