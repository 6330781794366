import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useShowConsole } from "../hooks"

const Seo = ({ 
  lang, 
  metaTitle,
  metaDescription,
  canonical,
  image,
  breadcrumbs,
  article
}) => {

  useShowConsole();

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
    >
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />

      <link rel="canonical" href={canonical}></link>

      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={image} />
      <link
          href="https://fonts.googleapis.com/css2?family=Fraunces&display=swap"
          rel="stylesheet"
      ></link>
      <script src="https://kit.fontawesome.com/6074c1e0b6.js"></script>

      <script async src="https://www.googletagmanager.com/gtag/js?id=G-LVNTY6TMWB"></script>

      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-LVNTY6TMWB');
        `}
      </script>

      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5581868270899806" crossOrigin="anonymous"></script>

      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MMWWQWF');
        `}
      </script>
      
      <noscript>
        {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MMWWQWF" height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}
      </noscript>

      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "LocalBusiness",
          "image": "${process.env.GATSBY_SITE_URL}/default-image.png",
          "name": "${process.env.GATSBY_SITE_NAME}",
          "url": "${process.env.GATSBY_SITE_URL}",
          "telephone": "${process.env.GATSBY_SITE_TELEPHONE}",
          "priceRange": "$$$",
          "address": {
              "@type": "PostalAddress",
              "streetAddress": "${process.env.GATSBY_SITE_ADDRESS}",
              "addressRegion": "HCM",
              "addressLocality": "Hồ Chí Minh",
              "postalCode": "700000",
              "addressCountry": "VN"
          },
          "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
              ],
              "opens": "08:00",
              "closes": "20:30"
          },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Saturday",
              "opens": "08:00",
              "closes": "12:00"
          }],
          "sameAs": [
            "https://www.youtube.com/channel/UCSpa8iwEcpH0LdR0LKZISXA",
            "https://twitter.com/Money24H1",
            "https://www.facebook.com/money24H.vn"
            ]
          }
        }
      `}</script>

      {breadcrumbs && <script type="application/ld+json">{`
        ${breadcrumbs}
      `}</script>}

      {article && <script type="application/ld+json">{`
        ${article}
      `}</script>}

    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  metaTitle: process.env.GATSBY_SITE_TITLE,
  metaDescription: process.env.GATSBY_SITE_DESCRIPTION,
  canonical: process.env.GATSBY_SITE_URL,
  image: `${process.env.GATSBY_SITE_URL}/default-image.png`,
  breadcrumbs: null,
  article: null,
}

Seo.propTypes = {
  lang: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  canonical: PropTypes.string,
  image: PropTypes.string,
  breadcrumbs: PropTypes.string
}

export default Seo
